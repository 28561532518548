.controlBar {
  background-color: rgba(0, 0, 0, 0.03);
  display: grid;
  grid-template-columns: 1fr auto;
}

.chatInput,
.sendButton {
  border-radius: 0;
  border: 2px solid transparent;
  background-color: transparent;
  padding: 12px 16px;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 767px) {
    padding: 12px;
  }
}

.sendButton {
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.sendButton:hover,
.chatInput:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.chatInput:focus,
.chatInput:focus-visible,
.sendButton:focus,
.sendButton:focus-visible {
  border-radius: 0;
  outline: none;
  border-color: rgba(152, 191, 100, 0.4);
}
