.container {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.dangerousFormBody {
  margin-top: 24px;
}

.dangerousFormBody {
  display: grid;
  gap: 12px;
}

.formInline {
  display: flex;
  gap: 8px;
}

.sourceInput {
  width: 370px;
  max-width: 100%;
}
