.container {
  display: grid;
  grid-template-columns: 3fr 1fr;

  @media screen and (max-width: 767px) {
    grid-template-columns: 30fr 70fr;
  }
}

.users {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 0;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 8px;
}

.user {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.editButton {
  margin-left: 8px;
}

.chat {
  height: 0;
  display: flex;
  flex-direction: column;
  box-shadow: -15px 0px 35px -11px rgba(152, 191, 100, 0.1);
  transition: height 0.4s ease-in-out;
}

.chat.open {
  height: 70vh;
}

.messages {
  flex: 1;
  overflow: auto;
}

.message {
  padding: 16px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  display: flex;

  @media screen and (max-width: 767px) {
    padding: 8px 12px;
  }
}

.author {
  margin-right: 6px;
  flex: auto;
  word-break: break-all;
  min-width: 60px;
}

.messageText {
  margin-right: 6px;
  flex: 1 1 100%;
  word-break: break-all;
}

.sentAt {
  margin-left: auto;
  font-size: 9px;
  flex: 0 0 50px;
  text-align: right;
}
