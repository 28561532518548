.main {
  display: grid;
  grid-template-columns: 1fr;
}

.player {
  height: 56.25vw;
  width: 100%;
  max-height: 85vh;
  background: #000;
}

.people {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
