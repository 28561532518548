.form {
  display: flex;
}

.inputWrapper {
  margin-right: 6px;
  display: inline-block;
}

.error {
  font-size: 12px;
  color: red;
  line-height: 1.5;
}
