.input {
  padding: 6px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.input:focus {
  border-color: var(--accent);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(152, 191, 100, 0.25);
}
