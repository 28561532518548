.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  background: white;
  padding: 16px;
  box-shadow: 0px 0px 60px 5px rgba(152, 191, 100, 0.43);
}

.header {
  display: flex;
  justify-content: flex-end;
}

.title {
  margin-right: auto;
}

.body {
  padding: 16px 0;
}

.hasModal {
  overflow: hidden;
}
